import React, { Component } from 'react';
import { Link, navigate } from 'gatsby';
import DrugAutoComplete from '../components/DrugAutoComplete';
import ActiveDrugs from '../components/ActiveDrugs';
import { Grid, FormHelperText, TextField } from '@material-ui/core';
import Layout from '../components/Layout';
import allSvc from '../services/allServices';
import StepComponent from '../components/StepComponent';
import CountrySearch from '../components/CountrySearch';
import MaterialCheckbox from '../components/MaterialCheckbox/MaterialCheckbox';
import DocModal from '../components/DocModal/DocModal';
import PrivacyPolicyDOCHQ from '../components/PrivacyPolicy/PrivacyPolicyDOCHQ';
import TermsAndConditionsDOCHQ from '../components/TermsAndConditions/TermsAndConditionsDOCHQ';
import Bottle from '../components/Images/Bottle';
import Carton from '../components/Images/Carton';
import useConfig from '../helpers/hooks/useConfig';
import { PRODUCTS } from '../helpers/constants';

const styles = {
  termsButton: {
    backgroundColor: 'transparent',
    color: 'var(--doc-green)',
    borderStyle: 'none',
    fontWeight: 'bold',
    padding: '0px',
  },
};

export default class MainPage extends Component {
  constructor() {
    super();

    this.state = {
      gotDrugs: false,
      drugList: [],
      activeDrugsList: [],
      locationId: '',
      destinationId: '',
      email: '',
      isDocHq: false,
      terms_accept: false,
      further_information_accept: false,
      unmatchedDrugs: false,
      errorModal: false,
      successModal: false,
      reportErrorModal: false,
      image1Modal: false,
      image2Modal: false,
      urlData: {},
      company: 'dochq',
      productSku: PRODUCTS.doctorNotes,
      defaultLocationId: 'GBR',
      isTermsVisible: false,
      isPrivacyVisible: false,
      countries: [{ name: 'Loading Countries...', id: 0 }],
    };

    this.getDrugs = getDrugs.bind(this);
    this.getCountries = getCountries.bind(this);
    this.updateUsageStats = updateUsageStats.bind(this);
    this.updateDrugList = updateDrugList.bind(this);
    this.updateLocation = updateLocation.bind(this);
    this.setDefaultLocation = setDefaultLocation.bind(this);
    this.updateDestinations = updateDestinations.bind(this);
    this.deleteDrug = deleteDrug.bind(this);
    this.checkUnmatched = checkUnmatched.bind(this);
    this.validForm = validForm.bind(this);
    this.submitForm = submitForm.bind(this);
    this.modalClose = modalClose.bind(this);
    this.createReport = createReport.bind(this);
    this.emailValidation = emailValidation.bind(this);
    this.getUrlParams = getUrlParams.bind(this);

    async function getDrugs(baseUrl) {
      if (this.state.gotDrugs !== true) {
        // Async get drugs
        allSvc
          .autocomplete(baseUrl)
          .then(response => {
            if (response.success && response.data) {
              this.setState({ gotDrugs: true, drugList: response.data });
            } else {
              this.setState({ gotDrugs: false });
            }
          })
          .catch(err => {
            this.setState({ gotDrugs: false });
          });
      }
    }
    async function getCountries(baseUrl) {
      allSvc
        .getCountries(baseUrl)
        .then(resp => {
          if (resp.success && resp.data) {
            this.setState({ countries: resp.data });
          }
        })
        .catch(err => {
          //TODO handle error
        });
    }
    async function updateUsageStats(baseUrl, body) {
      allSvc
        .updateUsageStats(baseUrl, body)
        .catch(err => {
          //TODO handle error
        });
    }
    function updateDrugList(item) {
      if (item && item.match === false && item.name && item.value) {
        item.name = item.value;
      }
      if (item && item.name) {
        // Only update the state with the new drug if the drug is either valid or a non match
        this.setState(
          prevState => ({
            activeDrugsList: [...prevState.activeDrugsList, item],
          }),
          () => {
            this.checkUnmatched();
          }
        );
      }
    }
    function updateLocation(locationId) {
      this.setState({ locationId });
    }
    function setDefaultLocation() {
      if (this.state.defaultLocationId) {
        this.setState({ locationId: this.state.defaultLocationId });
      }
    }
    function updateDestinations(destinationId) {
      this.setState({ destinationId });
    }
    function deleteDrug(name) {
      const newDrugList = this.state.activeDrugsList.filter(
        drug => drug && drug.name && drug.name.toLowerCase() !== name.toLowerCase()
      );
      this.setState({ activeDrugsList: newDrugList }, () => {
        this.checkUnmatched();
      });
    }
    function checkUnmatched() {
      this.state.activeDrugsList.filter(drug => {
        return drug.match === false ? true : false;
      }).length > 0
        ? this.setState({ unmatchedDrugs: true })
        : this.setState({ unmatchedDrugs: false });
    }
    function validForm(data) {
      const errors = [];
      if (!data.locationId.length) {
        errors.push('Select a location');
      }
      if (!data.destinationId.length) {
        errors.push('Select your destination');
      }
      if (!data.activeDrugsList.length) {
        errors.push('Enter active ingredients');
      }
      if (!validEmail(data.email)) {
        errors.push('Enter a valid email address');
      }
      if (!data.terms_accept) {
        errors.push('Please accept our terms and conditions');
      }
      if (errors.length === 0) {
        return true;
      } else {
        return errors;
      }
    }
    function validEmail(email) {
      const pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
      return pattern.test(email.toLowerCase());
    }
    function emailValidation(email) {
      if (email && email.length >= 4 && email.includes('@')) {
        return validEmail(email);
      } else {
        // Do not show error until user makes error
        return true;
      }
    }
    function submitForm() {
      const isValid = validForm(this.state);
      if (isValid && isValid === true) {
        this.createReport(
          this.state.config.activeGateway,
          this.state.activeDrugsList,
          this.state.locationId,
          this.state.destinationId,
          this.state.unmatchedDrugs,
          this.state.further_information_accept,
          this.state.isDocHq,
        );
      } else {
        //messy implementation
        this.setState({ errors: isValid, errorModal: true });
      }
    }
    function modalClose(modalName) {
      if (modalName === 'successModal') this.setState({ successModal: false });
      if (modalName === 'errorModal') this.setState({ errorModal: false });
      if (modalName === 'reportErrorModal') this.setState({ reportErrorModal: false });
    }

    function createReport(baseUrl, drugsList, locationId, destinationId, unmatchedDrugs, further_information_accept) {
      const matched_drugs = [];
      const unmatched_drugs = [];
      drugsList.forEach(drug => {
        if (drug.id && drug.name && typeof drug.match === 'undefined') {
          matched_drugs.push({ drug_id: drug.id });
        }
      });
      if (unmatchedDrugs !== false) {
        drugsList.forEach(drug => {
          if (drug && drug.name && drug.match === false) {
            unmatched_drugs.push({ drug: drug.name });
          }
        });
      }
      const dataObj = {
        from_country_id: locationId,
        to_country_id: destinationId,
        matched_drugs,
        unmatched_drugs,
        user_id: 'unauthorized user',
        email: this.state.email,
        marketing_accept: +further_information_accept,
        company: this.state.company,
      };

      allSvc
        .createReport(baseUrl, dataObj, this.state.company)
        .then(resp => {
          if (resp.success && resp.reportId) {
            navigate(`/report/?reportId=${resp.reportId}${this.state.company ? `&company=${this.state.company}` : ''}${this.state.productSku ? `&productSku=${this.state.productSku}` : ''}`);
          } else {
            this.setState({ reportErrorModal: true });
            throw new Error('Error sending report.');
          }
        })
        .catch(err => {
          this.setState({ reportErrorModal: true });
        });
    }
    function getUrlParams(locationString) {
      try {
        const hashes = locationString.slice(locationString.indexOf('?') + 1).split('&');
        const params = {};
        hashes.map(hash => {
          const match = hash.match(/([^=]+?)=(.+)/);
          const key = match[1];
          const val = match[2];
          params[key] = decodeURIComponent(val);
        });
        return params;
      } catch (error) {
        return { error: 'Error fetching url params' };
      }
    }
  }

  componentWillMount() {
    // When component is due to mount, fetch drug data
    const { config } = useConfig();
    this.getDrugs(config?.activeGateway);
    this.getCountries(config?.activeGateway);
    this.setDefaultLocation();

    this.setState({ config });

    const isClient = typeof window !== 'undefined';
    if (isClient) {
      const urlParams =
				typeof window === 'undefined' ? null : this.getUrlParams(window.location.href);
      const productSku = String(urlParams?.productSku || PRODUCTS.doctorNotes);
      const company = String(urlParams?.company || 'dochq').toLowerCase();
      this.setState({ isDocHq: true, company, productSku });
    }
  };

  componentDidMount() {
    this.updateUsageStats(this.state.config.activeGateway, { company: this.state.company });
  };

  render() {
    const urlParams =
			typeof window === 'undefined' ? null : this.getUrlParams(window.location.href);
    return (
      <Layout>
        <Grid
          container
          spacing={2}
          style={{
            margin: 'auto',
            maxWidth: '100%',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          {urlParams &&
					typeof urlParams.error === 'undefined' &&
					urlParams.displayTitle &&
					urlParams.displayTitle === 'false' ? null : (
              <Grid item xs={12}>
                <Grid container direction='column' justify='center' alignItems='center'>
                  <h2 style={{ color: 'var(--doc-green)' }}>
									Can I take my medicine abroad?
                  </h2>
                  <h4>
                    You could get a <strong>fine</strong> or go to <strong>prison</strong> if you
                    travel with medicine that's illegal in another country, check what rules apply to
                    your medicine.
                  </h4>
                </Grid>
              </Grid>
            )}
          <StepComponent stepNumber='1' stepMessage='Where are you travelling from?'>
            <CountrySearch
              id='location-select'
              label='Origin'
              updateLocation={this.updateLocation}
              countries={this.state.countries}
              defaultLocationId={this.state.defaultLocationId}
            />
          </StepComponent>
          <StepComponent stepNumber='2' stepMessage='Where are you travelling to?'>
            <CountrySearch
              id='destination-select'
              label='Destination'
              updateLocation={this.updateDestinations}
              countries={this.state.countries}
              defaultLocationId=''
            />
            <FormHelperText style={{ fontSize: '12px', maxWidth: '90%' }}>
							Check all destinations where you are walking through security with your medication.
            </FormHelperText>
          </StepComponent>
          <StepComponent
            stepNumber='3'
            stepMessage='What are the active ingredients in your medicine?'
          >
            <DrugAutoComplete
              drugList={this.state.drugList}
              updateDrugList={this.updateDrugList}
              disabled={this.state.isDocHq ? false : this.state.activeDrugsList.length >= 6}
            />
          </StepComponent>
          <Grid item xs={12} sm={12} md={6}>
            <ActiveDrugs
              activeDrugsList={this.state.activeDrugsList}
              deleteDrug={this.deleteDrug}
            />
            {this.state.unmatchedDrugs === true && (
              <FormHelperText style={{ fontSize: '12px', color: 'var(--red)' }}>
                <strong>Note: </strong> your unmatched drug was not found in our database. <br/>Please recheck the drug name for spelling mistakes.
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormHelperText style={{ fontSize: '12px', paddingBottom: '10px' }}>
							Where to find the active ingredient of your medicine
            </FormHelperText>
            <Grid
              container
              direction='row'
              alignItems='stretch'
              style={{ maxWidth: '90%' }}
              className='image-grid'
            >
              <div
                className='image-container'
                style={{ marginRight: '5px' }}
                onClick={() => this.setState({ image1Modal: true })}
              >
                <Bottle wrapStyle={{ maxWidth: '90%', minWidth: '150px' }} />
                <div className='middle'>
                  <div className='text'>Click here to expand</div>
                </div>
              </div>
              <div
                className='image-container'
                style={{ marginRight: '5px' }}
                onClick={() => this.setState({ image2Modal: true })}
              >
                <Carton wrapStyle={{ maxWidth: '90%', minWidth: '150px' }} />
                <div className='middle'>
                  <div className='text'>Click here to expand</div>
                </div>
              </div>
            </Grid>
          </Grid>
          <StepComponent stepNumber='4' stepMessage='Enter your email address'>
            <Grid item xs={12}>
              <Grid container direction='column' justify='center' alignItems='stretch'>
                <TextField
                  error={!this.emailValidation(this.state.email)}
                  inputProps={{ 'aria-label': 'Email address input' }}
                  variant='filled'
                  type='email'
                  id="email-input"
                  placeholder='Enter your Email'
                  value={this.state.email}
                  pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
                  onChange={e => {
                    this.setState({ email: e.target.value });
                  }}
                  style={{ color: '#A2A2A2', flex: 1, maxWidth: '90%' }}
                  required
                />
              </Grid>
            </Grid>
          </StepComponent>
          <Grid item xs={12}>
            <Grid
              container
              direction='row'
              justify='flex-start'
              alignItems='center'
              mb={2}
            >
              <MaterialCheckbox
                labelComponent={
                  <TermsLabel
                    togglePrivacy={() =>
                      this.setState(prevState => ({
                        isPrivacyVisible: !prevState.isPrivacyVisible,
                      }))
                    }
                    toggleTerms={() =>
                      this.setState(prevState => ({
                        isTermsVisible: !prevState.isTermsVisible,
                      }))
                    }
                  />
                }
                id="terms-conditions-checkbox"
                value={this.state.terms_accept}
                onChange={() => {
                  this.setState(prevState => ({
                    terms_accept: !prevState.terms_accept,
                  }));
                }}
              />
            </Grid>
            {/* <Grid container direction='row' justify='flex-start' alignItems='center'>
							<MaterialCheckbox
								labelComponent={
									<span
										style={{
											fontSize: '12px',
											wordBreak: 'wrap-word',
										}}
									>
										&nbsp;I'd like you to keep me up to date with DocHQ's latest offers and services
										by email.
									</span>
								}
								value={this.state.further_information_accept}
								onChange={() => {
									this.setState(prevState => ({
										further_information_accept: !prevState.further_information_accept,
									}));
								}}
							/>
						</Grid> */}
          </Grid>
          <Grid item xs={12}>
            <button
              id="submit-button"
              onClick={() => {
                this.submitForm();
              }}
              className='btn-swu'
            >
							Submit
            </button>
          </Grid>
        </Grid>
        <DocModal
          isVisible={this.state.errorModal}
          onClose={() => this.setState({ errorModal: false })}
          title='Please correct the following:'
          content={<ErrorModal errors={this.state.errors} />}
        />
        <DocModal
          isVisible={this.state.successModal}
          onClose={() => this.setState({ successModal: false })}
          title='Report created successfully.'
          content={
            <>
              <p>
								Thank you for using DocHQ Travel Medicine Restriction Checker.<br />
								A copy of your report has also been sent to {this.state.email}.<br />
								Please check also your spam folder.
              </p>
              <SuccessModal
                company={this.state.company}
                email={this.state.email}
                productSku={this.state.productSku}
                reportId={this.state.reportId}
              />
            </>
          }
        />
        <DocModal
          isVisible={this.state.reportErrorModal}
          title='An error occurred sending the report, please try again'
          hideBody
          onClose={() => this.setState({ reportErrorModal: false })}
        />
        <DocModal
          isVisible={this.state.image1Modal}
          onClose={() => this.setState({ image1Modal: false })}
          title='Where to find the active ingredient of your medicine'
          content={<Bottle wrapStyle={{ maxHeight: '60vh' }} imgStyle={{ width: 'auto', margin: 'auto', right: '0' }} />}
        />
        <DocModal
          isVisible={this.state.image2Modal}
          onClose={() => this.setState({ image2Modal: false })}
          title='Where to find the active ingredient of your medicine'
          content={<Carton wrapStyle={{ maxHeight: '60vh' }} imgStyle={{ width: 'auto', margin: 'auto', right: '0' }} />}
        />
        <DocModal
          isVisible={this.state.isTermsVisible}
          onClose={() => this.setState({ isTermsVisible: false })}
          title='Terms and Conditions'
          content={<TermsAndConditionsDOCHQ />}
        />
        <DocModal
          isVisible={this.state.isPrivacyVisible}
          onClose={() => this.setState({ isPrivacyVisible: false })}
          title='Privacy Policy'
          content={<PrivacyPolicyDOCHQ />}
        />
      </Layout>
    );
  }
}

const ErrorModal = props => (
  <React.Fragment>
    <ul>
      {props.errors.map((error, i) => {
        return <li key={i}>{error}</li>;
      })}
    </ul>
  </React.Fragment>
);

const SuccessModal = props => {
  return (
    <React.Fragment>
      <Link to={`/report/?reportId=${props.reportId}${props.company ? `&company=${props.company}` : ''}${props.productSku ? `&productSku=${props.productSku}` : ''}`}>
        <button className='btn-swu'>
					View Report
        </button>
      </Link>
    </React.Fragment>
  );
};

const TermsLabel = ({ togglePrivacy, toggleTerms }) => (
  <React.Fragment>
    <span
      style={{
        fontSize: '12px',
        wordBreak: 'wrap-word',
        maxWidth: '80%',
      }}
    >
      <span style={{ color: 'var(--danger)' }}>*</span>I have read and understood the{' '}
      <button className='terms-button-doc' style={styles.termsButton} onClick={togglePrivacy}>
        {' '}
				Privacy Policy
      </button>{' '}
			and{' '}
      <button className='terms-button-doc' style={styles.termsButton} onClick={toggleTerms}>
        {' '}
				Terms of Use{' '}
      </button>{' '}
			carefully{' '}
    </span>
  </React.Fragment>
);
